
export function Logo() {
  return (
    <div className="text-2xl font-bold overflow-clip h-12">
      Kodee
    </div>
  )
}

export function LogoSmall() {
  return (
    <div className="text-2xl font-bold h-12 mt-5">
      K
    </div>
  )
}
